import $ from 'jquery';
import throttle from 'lodash.throttle';

function stretchVideos() {
  const stretch_video = function() {
    $('.stretch-video').each(function() {
      const $this = $(this);

      const iframe = $this.children('iframe');
      const ratio = iframe.attr('height') / iframe.attr('width');
      const width = $this.width();

      iframe.css({
        width,
        height: width * ratio,
      });
    });

    $('iframe[allowfullscreen]').each(function() {
      const iframe = $(this);
      const ratio = iframe.attr('height') / iframe.attr('width');
      const width = iframe.parents('.the-content').width();

      if (width) {
        iframe.css({
          width,
          height: width * ratio,
        });
      }
    });
  };

  $(window).on('resize', throttle(stretch_video, 300));

  stretch_video();
}

export default stretchVideos;
