import React, {
  useState,
  Fragment,
  memo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import delve from 'dlv';
import { useSpring, config, animated } from 'react-spring/web.cjs';

import formatDate from '../helpers/formatDate';
import Share from '../components/Share';

import IconArrow from '../../images/icons/arrow.svg';
import ArrowLong from '../../images/icons/arrow-long.svg';
import CaretDown from '../../images/icons/caret-down.svg';
import SearchForm from '../components/SearchForm';
import InfiniteScroll from 'react-infinite-scroller';

const Page = props => {
  const isMounted = useRef(false);

  const { location, history, match } = props;

  let { pageNr, pageName, searchQuery = '', lang } = match.params;

  if (searchQuery) {
    pageNr = Number(queryString.parse(location.search).page || 1);
  } else {
    pageNr = Number(pageNr || 1);
  }

  const {
    pageData: { posts, page },
    pageVars,
  } = window;

  function getNextPageLink() {
    if (searchQuery) {
      return {
        pathname: location.pathname,
        search: queryString.stringify({
          page: pageNr + 1,
        }),
      };
    }

    return `/${lang}/${pageName}/${pageNr + 1}/`;
  }

  const [searchValue, setSearchValue] = useState(searchQuery);
  const [allPosts, setAllPosts] = useState(posts.items);
  const [totalPages, setTotalPages] = useState(posts.totalPages);
  const [loading, setLoading] = useState(false);

  const getPosts = useCallback(async () => {
    setLoading(true);

    const response = await fetch(
      `/wp-json/wp/v2/posts?${queryString.stringify({
        search: searchQuery ? searchQuery : undefined,
        per_page: pageVars.postsPerPage * pageNr,
        lang,
      })}`
    );

    const newPosts = await response.json();

    setAllPosts(newPosts);
    setTotalPages(response.headers.get('x-wp-totalpages'));

    setLoading(false);
  }, [pageVars, pageNr, searchQuery, lang]);

  useEffect(() => {
    if (isMounted.current) {
      getPosts();
    }

    isMounted.current = true;
  }, [pageNr, location.search, getPosts]);

  function handleSubmit(e) {
    e.preventDefault();

    if (searchValue) {
      history.push({
        pathname: `/${lang}/search/${searchValue}/`,
      });
    } else {
      history.push(`/${lang}/${page.slug}/`);
    }
  }

  function handleLoadMore() {
    setLoading(true);
    history.push(getNextPageLink());
  }

  const hasMorePages = pageNr < totalPages && !loading;

  return (
    <Fragment>
      <div className="news">
        <div className="news__header">
          <div className="container">
            <div className="columns is-vcentered is-flex-mobile">
              <div className="column">
                <h1 dangerouslySetInnerHTML={{ __html: page.title.rendered }} />
              </div>
              <div className="column is-one-quarter is-hidden-mobile">
                <SearchForm
                  handleSubmit={handleSubmit}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  placeholder={pageTranslations.search}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="hfeed news__feed">
        <div className="container">
          <div
            className={classNames([
              'loading-container',
              loading ? 'loading' : '',
            ])}
          >
            <InfiniteScroll
              pageStart={0}
              loadMore={handleLoadMore}
              hasMore={hasMorePages}
              loader={null}
              className="columns is-multiline"
              threshold={150}
            >
              <Posts acf={page.acf} posts={allPosts} />
            </InfiniteScroll>
          </div>
          <div className="columns mt2e">
            <div className="column is-half is-offset-one-quarter has-text-centered">
              {pageNr < totalPages && (
                <Link
                  className="button button--light-blue button--large"
                  to={getNextPageLink()}
                >
                  {pageTranslations.loadMore}
                  <CaretDown />
                </Link>
              )}
            </div>
            <div className="column is-one-quarter has-text-right">
              <button
                className="button button--light-blue button--large button--top"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                }
              >
                <CaretDown />
              </button>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

const Cta = ({ acf }) => {
  const { call_to_action, cta_title, cta_text, cta_button } = acf;

  if (!call_to_action) {
    return null;
  }

  return (
    <div className="column is-full is-two-thirds-desktop">
      <div className="news__cta">
        <div className="news__cta__inner">
          <h2>{cta_title}</h2>
          <div
            className="the-content"
            dangerouslySetInnerHTML={{ __html: cta_text }}
          />
          {cta_button.url && (
            <a
              href={cta_button.url}
              className="button"
              target={cta_button.target}
            >
              {cta_button.title}
              <span>
                <IconArrow />
                <IconArrow />
              </span>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

const Post = memo(({ post, index, acf }) => {
  const isLarge = index === 0;

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(1rem)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: index * 50,
    config: config.gentle,
  });

  return (
    <>
      {index === 4 && <Cta acf={acf} />}
      <animated.div
        style={springProps}
        className={classNames(
          'column',
          isLarge ? 'is-full-desktop' : 'is-half is-one-third-desktop'
        )}
      >
        <article
          className={classNames(
            'news__item',
            isLarge ? 'news__item--large' : 'news__item--small'
          )}
        >
          <header className="news__item__header">
            <a
              href={post.link}
              className="news__image scale-image"
              dangerouslySetInnerHTML={{
                __html: delve(post, [
                  'featured_image',
                  isLarge ? 'large' : 'small',
                  'rendered',
                ]),
              }}
            />
            <div className="news__meta">
              <p>
                {post.post_categories &&
                  `${post.post_categories.join(', ')} - `}
                {formatDate(
                  post.date,
                  'date',
                  pageVars.currentLanguage === 'nl' ? 'nl-NL' : 'en-EN'
                )}
              </p>
            </div>
          </header>
          <div className="news__inner">
            <h2>
              <a
                href={post.link}
                dangerouslySetInnerHTML={{ __html: post.title.rendered }}
              />
            </h2>
            <div>
              <div
                className="is-hidden-mobile"
                dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }}
              />
              <a href={post.link} className="read-more">
                <span className="animating-arrow">
                  <ArrowLong />
                </span>{' '}
                {pageTranslations.readMore}
              </a>
              <footer>
                <Share permalink={post.link} title={post.title.rendered} />
              </footer>
            </div>
          </div>
        </article>
      </animated.div>
    </>
  );
});

const Posts = memo(({ acf, posts }) => {
  if (posts.length === 0) {
    return (
      <div className="column is-full">
        <p>{pageTranslations.notFound}</p>
      </div>
    );
  }

  return posts.map((post, index) => {
    return <Post key={post.id} post={post} index={index} acf={acf} />;
  });
});

const PageWithRouter = () => (
  <Router>
    <Switch>
      <Route
        path="/:lang/search/:searchQuery/:pageNr?/"
        component={Page}
        excact
      />

      <Route path="/:lang/:pageName/:pageNr?/" component={Page} excact />
    </Switch>
  </Router>
);

export default {
  init() {
    ReactDOM.render(
      <PageWithRouter />,
      document.getElementById('root-archive')
    );
  },
};
