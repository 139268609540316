import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import ReactDOM from 'react-dom';
import React from 'react';
import Database from './database/database';

const Page = props => {
  const { pageTranslations } = window;

  const filters = [
    {
      key: 'subject',
      label: pageTranslations.subject,
    },
    {
      key: 'theme',
      label: pageTranslations.theme,
    },
    {
      key: 'film_language',
      label: pageTranslations.film_language,
    },
    {
      key: 'education_level',
      label: pageTranslations.education_level,
    },
    {
      key: 'duration',
      label: pageTranslations.duration,
    },
    {
      key: 'film_type',
      label: pageTranslations.film_type,
    },
  ];

  return <Database {...props} filters={filters} databaseType="education" />;
};

const PageWithRouter = () => (
  <Router>
    <Switch>
      <Route
        path={['/:lang/:pageName/', '/:lang/:pageName/page/:pageNr?/']}
        component={Page}
        exact
      />
    </Switch>
  </Router>
);

export default {
  init() {
    ReactDOM.render(
      <PageWithRouter />,
      document.getElementById('root-database')
    );
  },
};
