const typeOptions = {
  number: {},
  currency: {
    style: 'currency',
    currency: 'EUR',
  },
};

function formatNumber(number, type = 'number', locale = 'nl-NL') {
  return new Intl.NumberFormat(locale, typeOptions[type]).format(number);
}

export default formatNumber;
