import React from 'react';
import IconSearch from '../../images/icons/icon-search.svg';

const SearchForm = ({
  handleSubmit,
  searchValue,
  setSearchValue,
  placeholder,
}) => {
  const { pageTranslations } = window;

  return (
    <form
      role="search"
      method="get"
      className="searchform"
      action=""
      onSubmit={handleSubmit}
    >
      <input
        type="search"
        className="searchform__field"
        value={searchValue}
        name="s"
        onChange={e => setSearchValue(e.target.value)}
        placeholder={pageTranslations.search || ''}
      />
      <button type="submit" className="searchform__submit">
        <IconSearch />
      </button>
    </form>
  );
};

export default SearchForm;
