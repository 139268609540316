import $ from 'jquery';
import './style.scss';
import smoothscroll from 'smoothscroll-polyfill';
import 'whatwg-fetch';
import 'picturefill';

import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import archive from './routes/archive';
import program from './routes/program';
import singleFilm from './routes/single-film';
import timetable from './routes/timetable';
import databaseEducation from './routes/databaseEducation';
import databaseCatalogus from './routes/databaseCatalogus';

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
  common,
  home,
  pageTemplateTemplateDatabaseEducation: databaseEducation,
  pageTemplateTemplateCatalogus: databaseCatalogus,
  pageTemplateTemplateNews: archive,
  pageTemplateTemplateProgram: program,
  pageTemplateTemplateTimetable: timetable,
  archive,
  search: archive,
  singleFilm,
});

smoothscroll.polyfill();

/** Load Events */
$(document).ready(() => routes.loadEvents());
