import React, {
  Fragment,
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react';
import Pagination from 'react-js-pagination';
import queryString from 'query-string';
import classNames from 'classnames';

import ActiveFilters from '../../components/ActiveFilters';
import IconArrow from '../../../images/icons/arrow.svg';
import Filters from './Filters';
import Films from './Films';

const stringify = args =>
  queryString.stringify(args, {
    arrayFormat: 'index',
  });

const Database = props => {
  const isMounted = useRef(false);
  const filmsContainer = useRef(null);
  const { pageTranslations, pageData } = window;
  const { location, history, match, filters, databaseType } = props;
  let { pageNr, pageName, lang } = match.params;

  const durationItems = [
    {
      value: '0,45',
      label: pageTranslations.shortFilm,
    },
    {
      value: '45,999',
      label: pageTranslations.featureFilm,
    },
  ];

  pageNr = Number(pageNr || 1);

  const searchQuery = useMemo(
    () =>
      queryString.parse(location.search, {
        arrayFormat: 'index',
      }),
    [location.search]
  );

  const [searchValue, setSearchValue] = useState(searchQuery.search || '');
  const [allPosts, setAllPosts] = useState(pageData.posts.items);
  const [totalPosts, setTotalPosts] = useState(pageData.posts.totalItems);
  const [loading, setLoading] = useState(false);

  function handleFilterToggle(type, value) {
    const newValue = String(value);
    const currentValue = searchQuery[type];

    let arrayValue;
    if (Array.isArray(currentValue)) {
      if (currentValue.includes(newValue)) {
        arrayValue = currentValue.filter(val => val !== newValue);
      } else {
        arrayValue = [...currentValue, newValue];
      }
    } else {
      arrayValue = [newValue];
    }

    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: stringify({
        ...searchQuery,
        [type]: arrayValue,
      }),
    });
  }

  function handleClearFilters() {
    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: '',
    });
  }

  function handlePageChange(page) {
    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/page/${page}/`,
    });

    if (filmsContainer.current) {
      window.scrollTo({
        top: filmsContainer.current.offsetTop - 64,
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  function handleSubmit(e) {
    e.preventDefault();

    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: stringify({
        ...searchQuery,
        search: searchValue,
      }),
    });
  }

  const getPosts = useCallback(
    async page => {
      setLoading(true);

      let meta_query;
      if (Array.isArray(searchQuery.duration)) {
        meta_query = [];
        searchQuery.duration.forEach(value => {
          meta_query.push({
            key: 'duration',
            value: value.split(',').map(Number),
            compare: 'BETWEEN',
            type: 'NUMERIC',
          });
        });
        meta_query = JSON.stringify(meta_query);
      }

      let args;
      if (databaseType === 'education') {
        args = {
          ...pageData.posts.queryParams,
          film_language: searchQuery.film_language,
          theme: searchQuery.theme,
          film_type: searchQuery.film_type,
          education_level: searchQuery.education_level,
          subject: searchQuery.subject,
          search: searchQuery.search || undefined,
          meta_query,
          page,
        };
      } else if (databaseType === 'catalogus') {
        args = {
          ...pageData.posts.queryParams,
          theme: searchQuery.theme,
          film_type: searchQuery.film_type,
          edition: searchQuery.edition,
          search: searchQuery.search || undefined,
          meta_query,
          page,
        };
      }

      const response = await fetch(
        `/wp-json/wp/v2/film?${stringify(args, {
          arrayFormat: 'bracket',
        })}`
      );

      const newPosts = await response.json();

      setAllPosts(newPosts);
      setTotalPosts(response.headers.get('x-wp-total'));

      setLoading(false);
    },
    [pageData, pageNr, searchQuery]
  );

  useEffect(() => {
    if (isMounted.current) {
      getPosts(pageNr);
    }

    isMounted.current = true;
  }, [pageNr, searchQuery, getPosts]);

  return (
    <Fragment>
      <Filters
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleSubmit={handleSubmit}
        searchQuery={searchQuery}
        handleFilterSelect={handleFilterToggle}
        durationItems={durationItems}
        filters={filters}
      />
      <ActiveFilters
        searchQuery={searchQuery}
        handleFilterToggle={handleFilterToggle}
        handleClearFilters={handleClearFilters}
        durationItems={durationItems}
        filters={filters}
      />
      <div className="films" ref={filmsContainer}>
        <div className="container">
          <h2 className="films__title">{pageTranslations.resultsTitle}</h2>
          <p className="films__count">
            {pageTranslations.totalFound}: {totalPosts}
          </p>
          <div
            className={classNames([
              'loading-container',
              loading ? 'loading' : '',
            ])}
          >
            <Films films={allPosts} />
          </div>
          <div className="pagination__wrapper">
            {totalPosts > pageData.posts.itemsPerPage && (
              <Pagination
                activePage={pageNr}
                itemsCountPerPage={pageData.posts.itemsPerPage}
                totalItemsCount={totalPosts}
                pageRangeDisplayed={5}
                hideFirstLastPages
                onChange={handlePageChange}
                itemClassPrev="pagination__prev"
                itemClassNext="pagination__next"
                prevPageText={<IconArrow />}
                nextPageText={<IconArrow />}
                hideDisabled
              />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Database;
