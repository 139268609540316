import React, { useRef, Fragment } from 'react';
import classNames from 'classnames';
import useOnClickOutside from 'use-onclickoutside';

import IconArrow from '../../images/icons/arrow.svg';
import IconCross from '../../images/icons/cross.svg';

const Filter = ({
  type,
  label,
  items,
  currentValue,
  handleFilterSelect,
  size,
  isSingular,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = React.useState(false);
  useOnClickOutside(ref, () => setIsOpen(false));

  return (
    <div className="filter__container" ref={ref}>
      <button
        className={classNames([
          'filter__button',
          `filter__button--${size}`,
          isOpen ? 'filter__button--opened' : '',
        ])}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="filter__button__inner">
          {label}
          <span className="filter__button__icon">
            {isOpen ? (
              <IconCross />
            ) : (
              <Fragment>
                <IconArrow />
                <IconArrow />
              </Fragment>
            )}
          </span>
        </span>
      </button>
      {isOpen && (
        <div className="filter__dropdown">
          <ul className="filter__list">
            {items.map(item => {
              let isActive = false;

              if (isSingular && currentValue === String(item.value)) {
                isActive = true;
              } else if (
                Array.isArray(currentValue) &&
                currentValue.includes(String(item.value))
              ) {
                isActive = true;
              }

              return (
                <li
                  key={item.value}
                  className={classNames(isActive ? 'selected' : '')}
                  onClick={() =>
                    handleFilterSelect(type, item.value, isSingular)
                  }
                  dangerouslySetInnerHTML={{ __html: item.label }}
                />
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

Filter.defaultProps = {
  size: 'normal',
};

export default Filter;
