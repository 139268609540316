import React from 'react';
import classNames from 'classnames';
import IconCross from '../../images/icons/cross.svg';

const ActiveFilters = ({
  searchQuery,
  handleFilterToggle,
  handleClearFilters,
  filters,
  durationItems,
  timeFilterItems,
}) => {
  const { pageTranslations, termData, festivalDays } = window;

  const activeFilters = filters.reduce((acc, { key }) => {
    const filterValue = searchQuery[key];

    if (filterValue) {
      if (key === 'time') {
        let item = timeFilterItems.find(i => i.value === filterValue);

        if (item) {
          acc.push({
            ...item,
            key,
          });
        }
      } else {
        filterValue.forEach(value => {
          if (value) {
            let item;
            if (key === 'duration') {
              item = durationItems.find(i => i.value === value);
            } else if (key === 'date') {
              item = festivalDays.find(i => i.value === value);
            } else {
              const term = termData[key].find(
                item => String(item.term_id) === value
              );

              if (term) {
                item = {
                  value: term.term_id,
                  label: term.name,
                };
              }
            }

            if (item) {
              acc.push({
                ...item,
                key,
              });
            }
          }
        });
      }
    }

    return acc;
  }, []);

  return (
    <div
      className={classNames([
        'active-filters',
        activeFilters.length === 0 ? 'is-hidden-mobile' : '',
      ])}
    >
      <div className="container">
        <div className="active-filters__inner">
          <strong className="is-hidden-mobile">
            {pageTranslations.chosenFilters}
          </strong>
          {activeFilters.length > 0 ? (
            <ul className="active-filters__list is-hidden-mobile">
              {activeFilters.map(filter => (
                <li key={`${filter.key}-${filter.value}`}>
                  <button
                    className="remove-button active-filters__remove"
                    onClick={() =>
                      handleFilterToggle(
                        filter.key,
                        filter.value,
                        filter.key === 'time'
                      )
                    }
                  >
                    <span dangerouslySetInnerHTML={{ __html: filter.label }} />
                    <span className="icon">
                      <IconCross />
                    </span>
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <span className="active-filters__none is-hidden-mobile">
              {pageTranslations.noFiltersActive}
            </span>
          )}

          {activeFilters.length > 0 && (
            <button
              className="remove-button active-filters__remove active-filters__remove--all"
              onClick={handleClearFilters}
            >
              {pageTranslations.removeAllFilters}
              <span className="icon">
                <IconCross />
              </span>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ActiveFilters;
