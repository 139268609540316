import React from 'react';
import first from 'lodash.first';
import classNames from 'classnames';
import { useSpring, config, animated } from 'react-spring/web.cjs';
import InfiniteScroll from 'react-infinite-scroller';

import formatDate from '../../helpers/formatDate';
import IconTime from '../../../images/icons/icon-time.svg';
import IconLocation from '../../../images/icons/icon-location.svg';
import IconPrice from '../../../images/icons/icon-price.svg';
import formatNumber from '../../helpers/formatNumber';
import IconArrow from '../../../images/icons/arrow.svg';

const ViewingItems = React.memo(
  ({ viewings, handleLoadMore, hasMoreItems }) => {
    const { pageTranslations, pageVars, soldOut = [], cancelled = [] } = window;
    const locale = pageVars.currentLanguage === 'nl' ? 'nl-NL' : 'en-EN';

    if (viewings.length === 0) {
      return <p>{pageTranslations.notFound}</p>;
    }

    let currentHour = '';
    let currentDate = '';

    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={handleLoadMore}
        hasMore={hasMoreItems}
        loader={null}
        className="columns is-multiline"
        threshold={150}
      >
        {viewings.map((viewing, index) => {
          const date = formatDate(
            viewing.acf.startDateTime,
            'longdate',
            locale
          );
          // const hour = formatDate(viewing.acf.startDateTime, 'hour', locale);

          let renderItems = [];

          if (date !== currentDate) {
            renderItems.push(
              <div
                key={date}
                className="column is-full program__date__container"
              >
                <h2 className="program__date">{date}</h2>
              </div>
            );

            currentDate = date;
          }

          // if (hour !== currentHour) {
          //   renderItems.push(
          //     <div
          //       key={`${date}-${hour}`}
          //       className="column is-full program__hour__container"
          //     >
          //       <h3 className="program__hour">{hour}:00</h3>
          //     </div>
          //   );
          //
          //   currentHour = hour;
          // }

          renderItems.push(
            <Viewing
              key={viewing.id}
              viewing={viewing}
              index={index}
              locale={locale}
              soldOut={soldOut.includes(String(viewing.id))}
              cancelled={cancelled.includes(String(viewing.id))}
            />
          );

          return renderItems;
        })}
      </InfiniteScroll>
    );
  }
);

const Thumbnail = ({ image }) => {
  const [loaded, setLoaded] = React.useState(false);

  if (!image) {
    return null;
  }

  return (
    <img
      src={image}
      alt=""
      onLoad={() => setLoaded(true)}
      className={classNames([
        'film__image',
        loaded ? 'film__image--loaded' : '',
      ])}
    />
  );
};

const Viewing = React.memo(({ viewing, index, locale, soldOut, cancelled }) => {
  const { termData, pageTranslations } = window;

  const image = first(viewing.images || []);

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(1rem)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: index * 50,
    config: config.gentle,
  });

  const themes = viewing.programma_lijn.reduce((acc, id) => {
    const theme = termData.programma_lijn.find(item => item.term_id === id);

    if (theme) {
      acc.push(theme);
    }

    return acc;
  }, []);

  const locations = viewing.location.reduce((acc, id) => {
    const location = termData.location.find(item => item.term_id === id);

    if (location) {
      acc.push(location.name);
    }

    return acc;
  }, []);

  return (
    <animated.article
      className="column is-half is-one-third-desktop is-one-quarter-widescreen viewing__container"
      style={springProps}
    >
      <a
        href={viewing.link}
        className={classNames([
          'viewing__header',
          'scale-image',
          !image && 'viewing__header--placeholder',
        ])}
      >
        <Thumbnail image={image} />

        <h3
          className="viewing__title"
          dangerouslySetInnerHTML={{ __html: viewing.title.rendered }}
        />
      </a>
      <div className="viewing__inner">
        {themes.length > 0 && (
          <div
            className="viewing__theme theme-label"
            style={{
              backgroundColor: themes[0].color || 'black',
            }}
          >
            {themes[0].name}
          </div>
        )}
        <div className="viewing__content">
          <div
            className="viewing__excerpt"
            dangerouslySetInnerHTML={{ __html: viewing.card_excerpt.rendered }}
          />
          <ul className="viewing__info">
            <li>
              <IconTime />
              {formatDate(viewing.acf.startDateTime, 'time', locale)}-
              {formatDate(viewing.acf.endDateTime, 'time', locale)}
            </li>
            {locations.length > 0 && (
              <li>
                <IconLocation />
                {locations.join(', ')}
              </li>
            )}
            {viewing.acf.ticket_price && (
              <li>
                <IconPrice />
                {formatNumber(viewing.acf.ticket_price, 'currency')}
              </li>
            )}
          </ul>
        </div>
        {cancelled ? (
          <span className="button button--large button--blue button--centered button--disabled">
            {pageTranslations.cancelled}
          </span>
        ) : (
          <>
            {!soldOut && viewing.acf.ticket_url ? (
              <a
                href={viewing.acf.ticket_url}
                className={classNames('button button--large button--blue', {
                  'button--centered': soldOut,
                })}
                target="_blank"
                rel="nofollow noreferrer"
              >
                {viewing.acf.ticket_price > 0
                  ? pageTranslations.tickets
                  : pageTranslations.reserve}
                <span>
                  <IconArrow />
                  <IconArrow />
                </span>
              </a>
            ) : (
              <span className="button button--large button--blue button--centered button--disabled">
                {soldOut ? pageTranslations.soldOut : pageTranslations.free}
              </span>
            )}
          </>
        )}
      </div>
    </animated.article>
  );
});

export default ViewingItems;
