import $ from 'jquery';
import stretchVideos from '../helpers/stretchVideos';
import { Fancybox } from '@fancyapps/ui';

export const sliderCaret = `<svg xmlns="http://www.w3.org/2000/svg" width="15.482" height="28.707">
  <path d="M14.076 28.708a1.564 1.564 0 0 1-1.127-.442L.282 15.017a.921.921 0 0 1 0-1.325L12.949.443a1.675 1.675 0 0 1 1.97-.221.957.957 0 0 1 .281 1.546L3.167 14.355 15.2 26.942a.957.957 0 0 1-.281 1.545 1.672 1.672 0 0 1-.843.221z" fill="currentColor"/>
</svg>`;

export default {
  init() {
    stretchVideos();

    Fancybox.bind('a[href$="popup=1"]', {
      type: 'iframe',
      padding: 0,
      hideScrollbar: false,
    });

    $('.cb__accordeon h3').on('click', function() {
      $(this)
        .toggleClass('open')
        .next()
        .slideToggle();
    });

    $('.media__slider').slick({
      prevArrow: `<button type="button" class="slick-prev">${sliderCaret}</button>`,
      nextArrow: `<button type="button" class="slick-next">${sliderCaret}</button>`,
      adaptiveHeight: true,
    });

    $('.post__slider').slick({
      centerMode: true,
      centerPadding: '10%',
      prevArrow: `<button type="button" class="slick-prev is-hidden-mobile">${sliderCaret}</button>`,
      nextArrow: `<button type="button" class="slick-next is-hidden-mobile">${sliderCaret}</button>`,
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 639,
          settings: {
            centerPadding: '20%',
          },
        },
        {
          breakpoint: 1279,
          settings: {
            centerPadding: '25%',
          },
        },
        {
          breakpoint: 1599,
          settings: {
            centerPadding: '30%',
          },
        },
      ],
    });

    $('.menu-toggle').on('click', function() {
      $('html').toggleClass('main-nav-opened');
    });
  },
  finalize() {},
};
