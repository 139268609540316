import React from 'react';
import Filter from '../../components/Filter';
import SearchForm from '../../components/SearchForm';

function getFilterItems(terms) {
  if (typeof terms !== 'object') {
    return [];
  }

  if (Array.isArray(terms)) {
    return terms.map(term => ({
      value: term.term_id,
      label: term.name,
    }));
  }

  return Object.values(terms).map(term => ({
    value: term.term_id,
    label: term.name,
  }));
}

const Filters = ({
  handleFilterSelect,
  searchValue,
  setSearchValue,
  handleSubmit,
  searchQuery,
  durationItems,
  filters,
}) => {
  const { pageTranslations, termData } = window;

  return (
    <section className="database__header__section">
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds is-half-desktop">
            <h2 className="filters__header">{pageTranslations.filterLabel}</h2>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-full is-three-quarters-desktop">
            <div className="columns is-multiline database__filters">
              {filters.map(({ key, label }) => (
                <div className="column is-half is-one-third-desktop" key={key}>
                  <Filter
                    currentValue={searchQuery[key]}
                    size="small"
                    label={label}
                    type={key}
                    items={
                      key === 'duration'
                        ? durationItems
                        : getFilterItems(termData[key])
                    }
                    handleFilterSelect={handleFilterSelect}
                  />
                </div>
              ))}
              <div className="column is-half is-hidden-desktop">
                <SearchForm
                  handleSubmit={handleSubmit}
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                  placeholder={'Zoeken'}
                />
              </div>
            </div>
          </div>
          <div className="column is-half is-one-quarter-desktop is-hidden-touch">
            <SearchForm
              handleSubmit={handleSubmit}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder={'Zoeken'}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Filters;
