import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Filters from './program/Filters';
import ViewingItems from './program/ViewingItems';
import ActiveFilters from '../components/ActiveFilters';
import SearchForm from '../components/SearchForm';
import CaretDown from '../../images/icons/caret-down.svg';

const stringify = args =>
  queryString.stringify(args, {
    arrayFormat: 'index',
  });

let timeFilterItems = [];
for (let hour = 10; hour <= 23; hour++) {
  timeFilterItems.push({
    label: `${hour}:00`,
    value: `${hour}`,
  });
}

const Program = props => {
  const isMounted = useRef(false);
  const filmsContainer = useRef(null);
  const {
    pageTranslations,
    pageData,
    festivalDays,
    loadMoreStartDate,
  } = window;
  const { location, history, match } = props;
  let { pageName, lang } = match.params;

  const filters = [
    {
      key: 'date',
      label: pageTranslations.date,
    },
    {
      key: 'time',
      label: pageTranslations.time,
    },
    {
      key: 'programma_lijn',
      label: pageTranslations.programma_lijn,
    },
    {
      key: 'programma_tag',
      label: pageTranslations.programma_tag,
    },
  ];

  const searchQuery = useMemo(
    () =>
      queryString.parse(location.search, {
        arrayFormat: 'index',
      }),
    [location.search]
  );

  const [searchValue, setSearchValue] = useState(searchQuery.search || '');
  const [allPosts, setAllPosts] = useState(pageData.posts.items);
  const [loading, setLoading] = useState(false);

  function handleFilterToggle(type, value, isSingular) {
    const newValue = String(value);
    const currentValue = searchQuery[type];

    let newFilterValue;
    if (isSingular) {
      newFilterValue = currentValue === value ? undefined : value;
    } else if (type === 'date') {
      // fixes issue with multiple date queries being very slow
      newFilterValue =
        currentValue && currentValue[0] === value ? undefined : [newValue];
    } else {
      if (Array.isArray(currentValue)) {
        if (currentValue.includes(newValue)) {
          newFilterValue = currentValue.filter(val => val !== newValue);
        } else {
          newFilterValue = [...currentValue, newValue];
        }
      } else {
        newFilterValue = [newValue];
      }
    }

    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: stringify({
        ...searchQuery,
        [type]: newFilterValue,
      }),
    });
  }

  function handleClearFilters() {
    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: '',
    });
  }

  function handleSubmit(e) {
    e.preventDefault();

    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: stringify({
        ...searchQuery,
        search: searchValue,
      }),
    });
  }

  function handleLoadMore() {
    let limitDate;
    if (searchQuery.limit_date) {
      const index = festivalDays.findIndex(
        item => item.value === searchQuery.limit_date
      );

      if (index < festivalDays.length - 1) {
        limitDate = festivalDays[index + 1].value;
      } else {
        limitDate = searchQuery.limit_date;
      }
    } else {
      limitDate = loadMoreStartDate;
    }

    setLoading(true);

    history.push({
      ...location,
      pathname: `/${lang}/${pageName}/`,
      search: stringify({
        ...searchQuery,
        limit_date: limitDate,
      }),
    });
  }

  const getPosts = useCallback(async () => {
    setLoading(true);

    let meta_query = undefined;

    const response = await fetch(
      `/wp-json/wp/v2/vertoning?${stringify(
        {
          ...pageData.posts.queryParams,
          programma_lijn: searchQuery.programma_lijn,
          programma_tag: searchQuery.programma_tag,
          date: searchQuery.date,
          time: searchQuery.time,
          limit_date: searchQuery.limit_date,
          search: searchQuery.search || undefined,
          meta_query,
        },
        {
          arrayFormat: 'bracket',
        }
      )}`
    );

    const newPosts = await response.json();

    setAllPosts(newPosts);

    setLoading(false);
  }, [pageData, searchQuery]);

  useEffect(() => {
    if (isMounted.current) {
      getPosts();
    }

    isMounted.current = true;
  }, [searchQuery, getPosts]);

  let showLoadMore =
    new Date(searchQuery.limit_date || festivalDays[0].value) <
      new Date(festivalDays[festivalDays.length - 1].value) && !loading;

  if (
    searchQuery.search ||
    searchQuery.date ||
    searchQuery.time ||
    searchQuery.programma_lijn ||
    searchQuery.programm_tag
  ) {
    showLoadMore = false;
  }

  return (
    <Fragment>
      <div className="program__header">
        <div className="container">
          {pageData.page.acf.intro_text && (
            <div
              className="program__intro"
              dangerouslySetInnerHTML={{
                __html: pageData.page.acf.intro_text,
              }}
            />
          )}
          <div className="columns is-vcentered">
            <div className="column is-half is-three-quarters-desktop">
              <h1
                dangerouslySetInnerHTML={{
                  __html: pageData.page.title.rendered,
                }}
              />
            </div>
            <div className="column is-half is-one-quarter-desktop">
              <SearchForm
                handleSubmit={handleSubmit}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                placeholder={pageTranslations.search}
              />
            </div>
          </div>
        </div>
        <Filters
          searchQuery={searchQuery}
          handleFilterSelect={handleFilterToggle}
          filters={filters}
          timeFilterItems={timeFilterItems}
        />
      </div>
      <ActiveFilters
        searchQuery={searchQuery}
        handleFilterToggle={handleFilterToggle}
        handleClearFilters={handleClearFilters}
        filters={filters}
        timeFilterItems={timeFilterItems}
      />
      <div className="films" ref={filmsContainer}>
        <div className="container">
          <div className="columns">
            <div className="column is-full">
              <ul className="change-view">
                <li>
                  <span>{pageTranslations.listView}</span>
                </li>
                <li>
                  <a href={window.blockViewUrl}>{pageTranslations.blockView}</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={classNames([
              'loading-container',
              loading ? 'loading' : '',
            ])}
          >
            <ViewingItems
              viewings={allPosts}
              handleLoadMore={handleLoadMore}
              hasMoreItems={showLoadMore}
            />
          </div>
          <div className="columns mt2e">
            <div className="column is-half is-offset-one-quarter has-text-centered">
              {showLoadMore && (
                <button
                  className="button button--light-blue button--large"
                  onClick={handleLoadMore}
                >
                  {pageTranslations.loadMore}
                  <CaretDown />
                </button>
              )}
            </div>
            <div className="column is-one-quarter has-text-right">
              <button
                className="button button--light-blue button--large button--top"
                onClick={() =>
                  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
                }
              >
                <CaretDown />
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const PageWithRouter = () => (
  <Router>
    <Switch>
      <Route path="/:lang/:pageName/" component={Program} excact />
    </Switch>
  </Router>
);

export default {
  init() {
    ReactDOM.render(
      <PageWithRouter />,
      document.getElementById('root-program')
    );
  },
};
