import React from 'react';
import first from 'lodash.first';
import classNames from 'classnames';
import delve from 'dlv';
import { useSpring, config, animated } from 'react-spring/web.cjs';

import IconArrow from '../../../images/icons/arrow.svg';

const Films = React.memo(({ films }) => {
  const { pageTranslations, termData } = window;

  if (films.length === 0) {
    return <p>{pageTranslations.notFound}</p>;
  }

  return (
    <div className="columns is-multiline">
      {films.map((film, index) => (
        <Film key={film.id} film={film} index={index} />
      ))}
    </div>
  );
});

const Thumbnail = ({ image }) => {
  const [loaded, setLoaded] = React.useState(false);

  if (!image) {
    return null;
  }

  return (
    <img
      src={image}
      alt=""
      onLoad={() => setLoaded(true)}
      className={classNames([
        'film__image',
        loaded ? 'film__image--loaded' : '',
      ])}
    />
  );
};

const Film = React.memo(({ film, index }) => {
  const image = first(film.images);

  const languageIds = delve(film, 'film_language', []);
  const languages = languageIds.reduce((acc, id) => {
    const name = delve(
      termData['film_language'].find(item => item.term_id === id),
      'name'
    );

    if (name) {
      acc.push(name);
    }

    return acc;
  }, []);

  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(1rem)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: index * 50,
    config: config.gentle,
  });

  return (
    <animated.article
      className="column is-half is-one-third-desktop is-one-quarter-widescreen film__container"
      style={springProps}
    >
      <a
        href={film.link}
        className={classNames([
          'film__header',
          'scale-image',
          !image && 'film__header--placeholder',
        ])}
      >
        <Thumbnail image={image} />

        <h3
          className="film__title"
          dangerouslySetInnerHTML={{ __html: film.title.rendered }}
        />
      </a>
      <div className="film__inner">
        <dl className="film__meta">
          {film.acf.director && (
            <>
              <dt>Regisseur</dt>
              <dd>{film.acf.director}</dd>
            </>
          )}
          {languages.length > 0 && (
            <>
              <dt>Taal</dt>
              <dd>{languages.join(', ')}</dd>
            </>
          )}
          {film.acf.duration && (
            <>
              <dt>Duur</dt>
              <dd>{film.acf.duration} min</dd>
            </>
          )}
        </dl>
        <a href={film.link} className="button button--blue">
          {pageTranslations.toFilmPage}
          <span>
            <IconArrow />
            <IconArrow />
          </span>
        </a>
      </div>
    </animated.article>
  );
});

export default Films;
