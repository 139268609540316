import $ from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar';

export default {
  init() {
    const container = document.querySelector('.scrollbar-x');
    new PerfectScrollbar(container, {
      useBothWheelAxes: true,
      suppressScrollY: true,
    });

    $('.scroll-button').on('click', function() {
      const scrollLeft = $(this).hasClass('scroll-left') ? '-=400' : '+=400';

      $('.scrollbar-x').animate({
        scrollLeft: scrollLeft,
      });
    });
  },
};
