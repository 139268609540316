import React from 'react';
import queryString from 'query-string';

import IconTwitter from '../../images/icons/icon-share-twitter.svg';
import IconFacebook from '../../images/icons/icon-share-facebook.svg';

const Share = ({ permalink, title }) => {
  return (
    <ul className="share">
      <li>
        <a
          href={`http://twitter.com/intent/tweet?${queryString.stringify({
            url: permalink,
            text: title,
          })}`}
          target="_blank"
          className="social-twitter"
          rel="noopener"
        >
          <IconTwitter />
        </a>
      </li>
      <li>
        <a
          href={`http://www.facebook.com/sharer.php?${queryString.stringify({
            s: 100,
            'p[title]': title,
            'p[url]': permalink,
          })}`}
          target="_blank"
          className="social-facebook"
          rel="noopener"
        >
          <IconFacebook />
        </a>
      </li>
    </ul>
  );
};

export default Share;
