import React from 'react';
import Filter from '../../components/Filter';
import SearchForm from '../../components/SearchForm';

function getFilterItems(terms) {
  if (typeof terms !== 'object') {
    return [];
  }

  if (Array.isArray(terms)) {
    return terms.map(term => ({
      value: term.term_id,
      label: term.name,
    }));
  }

  return Object.values(terms).map(term => ({
    value: term.term_id,
    label: term.name,
  }));
}

const Filters = ({
  handleFilterSelect,
  searchQuery,
  filters,
  timeFilterItems,
}) => {
  const { pageTranslations, termData, festivalDays } = window;

  return (
    <section>
      <div className="container">
        <div className="columns">
          <div className="column is-two-thirds is-half-desktop">
            <h2 className="filters__header">{pageTranslations.filterLabel}</h2>
          </div>
        </div>
        <div className="columns is-multiline">
          <div className="column is-full">
            <div className="columns is-multiline program__filters">
              <div className="column is-half is-one-quarter-desktop">
                <Filter
                  currentValue={searchQuery['date']}
                  size="small"
                  label={pageTranslations.date}
                  type={'date'}
                  items={festivalDays}
                  handleFilterSelect={handleFilterSelect}
                />
              </div>
              <div className="column is-half is-one-quarter-desktop">
                <Filter
                  currentValue={searchQuery['time']}
                  size="small"
                  label={pageTranslations.time}
                  type={'time'}
                  items={timeFilterItems}
                  handleFilterSelect={handleFilterSelect}
                  isSingular
                />
              </div>
              {filters.map(({ key, label }) => {
                if (['date', 'time'].includes(key)) {
                  return null;
                }

                return (
                  <div
                    className="column is-half is-one-quarter-desktop"
                    key={key}
                  >
                    <Filter
                      currentValue={searchQuery[key]}
                      size="small"
                      label={label}
                      type={key}
                      items={getFilterItems(termData[key])}
                      handleFilterSelect={handleFilterSelect}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Filters;
