import $ from 'jquery';
import 'slick-carousel';

export default {
  init() {
    $('.credits__slider').slick({
      fade: true,
      autoplay: true,
      autoplaySpeed: 2000,
      arrows: false,
    });
  },
};
